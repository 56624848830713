<template>
  <div>
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="font-weight-bold text-uppercase">User Details</h1>
          </b-col>
        </b-row>
        <b-row
          class="no-gutters bg-white-border mt-4"
          v-bind:class="$isLoading ? 'd-none' : 'd-block'"
        >
          <b-col class="px-4 px-sm-5 py-4">
            <HeaderLine text="Profile" :isLine="false" class="mb-3" />
            <b-row>
              <b-col md="6">
                <div class="form-group display-custom">
                  <label class="control-label">Customer Name</label>
                  <div class="my-1">
                    <p class="form-control-static">
                      {{ customer.customerName }}
                    </p>
                  </div>
                </div>
                <!-- <InputText
                  v-model="customer.customerName"
                  textFloat="Customer Name"
                  placeholder="Customer Name"
                  type="text"
                  name="customerName"
                  isRequired
                  :isValidate="$v.customer.customerName.$error"
                  :v="$v.customer.customerName"
                />-->
              </b-col>
              <b-col md="6">
                <div class="form-group display-custom">
                  <label class="control-label">Telephone No.</label>
                  <div class="my-1">
                    <p class="form-control-static">
                      {{ customer.mobileNumber }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <div class="form-group display-custom">
                  <label class="control-label">Birthday</label>
                  <div class="my-1">
                    <p class="form-control-static">
                      {{ customer.birthDay | moment($formatDate) }}
                    </p>
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="form-group display-custom">
                  <label class="control-label">Contract No.</label>
                  <div class="my-1">
                    <p class="form-control-static">{{ customer.contractNo }}</p>
                  </div>
                </div>
                <!-- <label class="label-text">
                  Birthday
                  <span class="text-danger">*</span>
                </label>
                <datetime
                  v-model="customer.birthDay"
                  placeholder="Please select date"
                  class="date-picker"
                  format="dd MMM yyyy"
                ></datetime>-->
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <div class="form-group display-custom">
                  <label class="control-label">ID Card</label>
                  <div class="my-1">
                    <p class="form-control-static">{{ customer.idCard }}</p>
                  </div>
                </div>
              </b-col>
            </b-row>
            <!-- <HeaderLine text="Line Connect" :isLine="false" class="mt-2" /> -->
            <div class="my-3 position-relative">
              <span class="font-weight-bold sub-title-name">Line Connect</span>
            </div>
            <b-row class="justify-content-start my-3">
              <b-col>
                <img
                  v-if="customer.linePictureUrl != ''"
                  :src="customer.linePictureUrl"
                  alt="Line Picture"
                  height="80"
                  width="80"
                  class="rounded-circle"
                />
                <img
                  v-else
                  src="@/assets/images/icons/user.png"
                  alt="Line Picture"
                  height="80"
                  width="80"
                  class="rounded-circle"
                />
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col md="6">
                <div class="form-group display-custom">
                  <label class="control-label">Line Name</label>
                  <div class="my-1">
                    <p
                      class="form-control-static"
                      v-if="customer.lineName != ''"
                    >
                      {{ customer.lineName }}
                    </p>
                    <p class="form-control-static" v-else>-</p>
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="form-group display-custom">
                  <label class="control-label">Status</label>
                  <div v-if="customer.active" class="form-inline my-1">
                    <p class="control-label mr-2 sub-status">Register</p>
                    <a
                      class="mx-1 sub-status-link font-weight-bold"
                      href="#"
                      @click.prevent="openModalUpdateStatus(0)"
                      v-if="customer.active"
                      >Unregister</a
                    >
                  </div>
                  <div v-if="!customer.active" class="form-inline my-1">
                    <p class="control-label mr-2 sub-status">Unregister</p>
                    <!-- <a
                      class="mx-1 sub-status-link"
                      href="#"
                      @click.prevent="openModalUpdateStatus(1)"
                    >Register</a>-->
                  </div>
                  <!-- <b-button
                    v-if="customer.active"
                    variant="success"
                    @click="openModalUpdateStatus(0)"
                  >Unregister</b-button>
                  <b-button
                    v-if="!customer.active"
                    variant="success"
                    @click="openModalUpdateStatus(1)"
                  >Register</b-button>-->
                </div>
              </b-col>
            </b-row>
            <b-row v-if="!customer.active">
              <b-col md="6" class="mx-2 my-2">
                <InputCheckBox
                  text="Display Contract 10 Digit"
                  :value="customer.contractTenDigit"
                  checkedvalue="1"
                  uncheckedvalue="0"
                  @onDataChange="onChangeCheckBoxContractTenDigit"
                />
              </b-col>
            </b-row>
            <!-- Report -->
            <div v-if="userId != 0" class="mt-5">
              <HeaderLine text="Report" class="mb-3" :isLine="false" />
              <div class="d-block">
                <div role="tablist">
                  <b-card
                    v-for="(item, i) in formTable"
                    :key="i"
                    no-body
                    class="tab-title-collapse"
                  >
                    <b-card-header
                      header-tag="header"
                      class="p-1 d-flex justify-content-between"
                      role="tab"
                    >
                      <b-button
                        v-b-toggle="'accordion-' + i"
                        @click="onClickTab(i)"
                        class="btn-collapse"
                      >
                        <font-awesome-icon icon="chevron-right" class="icon" />
                        <font-awesome-icon icon="chevron-down" class="icon" />
                        <span class="template-color-green">
                          {{ " " + item.titleTab }}
                        </span>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-' + i"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <div class="d-flex justify-content-end my-3">
                        <b-button
                          class="btn btn-main-2 mr-2 text-nowrap"
                          @click="exportData(1, i)"
                          >EXPORT EXCEL</b-button
                        >
                        <b-button
                          class="btn btn-main text-nowrap"
                          @click="exportData(2, i)"
                          >EXPORT CSV</b-button
                        >
                      </div>
                      <b-table
                        striped
                        responsive
                        hover
                        :items="formTable[i].items"
                        :fields="formTable[i].tableHeaders"
                        :busy="isBusy"
                        show-empty
                        empty-text="No matching records found"
                        thead-class="text-nowrap"
                        tbody-class="text-nowrap"
                      >
                        <template v-slot:cell(transactionDate)="data">
                          <div v-if="data.item.transactionDate == null">-</div>
                          <div v-else>
                            <span>
                              {{
                                data.item.transactionDate
                                  | moment($formatDateTime)
                              }}
                            </span>
                          </div>
                        </template>
                        <template v-slot:cell(asOfDate)="data">
                          <div v-if="data.item.asOfDate == null">-</div>
                          <div v-else>
                            <span>{{ data.item.asOfDate }}</span>
                          </div>
                        </template>
                        <template v-slot:cell(nextPaymentDueDate)="data">
                          <div v-if="data.item.nextPaymentDueDate == null">
                            -
                          </div>
                          <div v-else>
                            <span>{{ data.item.nextPaymentDueDate }}</span>
                          </div>
                        </template>
                        <template v-slot:cell(transferPaymentDueDate)="data">
                          <div v-if="data.item.transferPaymentDueDate == null">
                            -
                          </div>
                          <div v-else>
                            <span>{{ data.item.transferPaymentDueDate }}</span>
                          </div>
                        </template>
                        <template v-slot:cell(riPaymentDueDate)="data">
                          <div v-if="data.item.riPaymentDueDate == null">-</div>
                          <div v-else>
                            <span>{{ data.item.riPaymentDueDate }}</span>
                          </div>
                        </template>

                        <template v-slot:cell(overdueDueDate)="data">
                          <div v-if="data.item.overdueDueDate == null">-</div>
                          <div v-else>
                            <span>{{ data.item.overdueDueDate }}</span>
                          </div>
                        </template>
                        <template v-slot:cell(registerDate)="data">
                          <div v-if="data.item.registerDate == null">-</div>
                          <div v-else>
                            <span>
                              {{
                                data.item.registerDate | moment($formatDateTime)
                              }}
                            </span>
                          </div>
                        </template>
                        <template v-slot:cell(unregisterDate)="data">
                          <div v-if="data.item.unregisterDate == null">-</div>
                          <div v-else>
                            <span>
                              {{
                                data.item.unregisterDate
                                  | moment($formatDateTime)
                              }}
                            </span>
                          </div>
                        </template>

                        <template v-slot:cell(notificationSendDate)="data">
                          <div v-if="data.item.notificationSendDate == null">
                            -
                          </div>
                          <div v-else>
                            <span>
                              {{
                                data.item.notificationSendDate
                                  | moment($formatDateTime)
                              }}
                            </span>
                          </div>
                        </template>
                        <template v-slot:cell(recieptDate)="data">
                          <div v-if="data.item.recieptDate == null">-</div>
                          <div v-else>
                            <span>{{ data.item.recieptDate }}</span>
                          </div>
                        </template>
                        <template v-slot:cell(recieptCancelDate)="data">
                          <div v-if="data.item.recieptCancelDate == null">
                            -
                          </div>
                          <div v-else>
                            <span>{{ data.item.recieptCancelDate }}</span>
                          </div>
                        </template>
                        <template v-slot:cell(returnCashDate)="data">
                          <div v-if="data.item.returnCashDate == null">-</div>
                          <div v-else>
                            <span>{{ data.item.returnCashDate }}</span>
                          </div>
                        </template>
                        <template v-slot:cell(created)="data">
                          <div v-if="data.item.created == null">-</div>
                          <div v-else>
                            <span>{{ data.item.created }}</span>
                          </div>
                        </template>

                        <template v-slot:table-busy>
                          <div class="text-center text-black my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong class="ml-2">Loading...</strong>
                          </div>
                        </template>
                      </b-table>
                      <b-row>
                        <b-col
                          class="form-inline justify-content-center justify-content-md-between"
                        >
                          <b-row>
                            <b-col>
                              <div class="d-inline-block layout-paging">
                                <b-pagination
                                  v-model="formTable[i].filter.page"
                                  :total-rows="formTable[i].rows"
                                  :per-page="formTable[i].filter.pageSize"
                                  class="m-md-0"
                                  @change="pagination"
                                  align="center"
                                ></b-pagination>
                              </div>
                            </b-col>
                            <div class="d-inline-block ml-md-3 layout-paging">
                              <p class="total-record-paging text-nowrap">
                                {{ formTable[i].totalRowMessage }}
                              </p>
                            </div>
                          </b-row>
                          <b-form-select
                            @change="hanndleChangePerpage"
                            v-model="formTable[i].filter.pageSize"
                            :options="pageOptions"
                          ></b-form-select>
                        </b-col>
                      </b-row>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
            <!-- End Report -->

            <!-- Button Cancel  Exit -->
            <b-row class="mt-5">
              <b-col md="6">
                <button
                  v-if="userId != 0"
                  type="button"
                  class="btn btn-danger btn-details-set text-uppercase"
                  @click="openModalDelete(customer)"
                  :disabled="isDisable"
                >
                  Delete
                </button>
                <b-button
                  to="/"
                  class="btn-details-set ml-md-2"
                  :disabled="isDisable"
                  >CANCEL</b-button
                >
              </b-col>
              <b-col md="6" class="text-sm-right">
                <button
                  v-if="userId != 0"
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm(0)"
                  :disabled="isDisable"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm(1)"
                  :disabled="isDisable"
                >
                  Save & Exit
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </form>
    <!-- Modal Result -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ"
      :text="modalMessage"
      btnConfirm="Delete"
      colorBtnConfirm="danger"
      btnCancel="Close"
      ref="ModalAlertConfirmDelete"
      @confirm="btnDelete"
    />
    <ModalAlertConfirm
      msg="ยืนยันการเปลี่ยน"
      :text="modalMessage"
      btnConfirm="Yes"
      colorBtnConfirm="success"
      btnCancel="Close"
      ref="ModalAlertConfirmUpdateStatus"
      @confirm="btnUpdateStatus"
    />

    <b-modal ref="loadingModal" hide-header hide-footer centered>
      <div class="text-center">
        <img
          src="@/assets/images/icons/loading.svg"
          alt="loading"
          class="mb-3 w-25"
        />
        <h1 class="font-weight-bold text-modal">
          In progress. Exporting Data...
        </h1>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import HeaderLine from "@/components/HeaderLine";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import InputCheckBox from "@/components/inputs/InputCheckBox";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import formTable from "@/components/reports/FormTableReports.js";

import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "UserDetails",
  components: {
    HeaderLine,
    InputText,
    InputSelect,
    InputCheckBox,
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    formTable,
  },
  data() {
    return {
      userId: this.$route.params.id,
      formTable,
      isDisable: false,
      // statusActive: null,
      listStatus: [
        {
          value: 0,
          text: "Unregister",
        },
        {
          value: 1,
          text: "Register",
        },
      ],
      fieldContractTenDigit: null,
      customer: {
        userId: null,
        customerName: "",
        mobileNumber: "",
        idCard: "",
        contractNo: "",
        birthDay: "",
        // active: null,
        contractTenDigit: false,
        lineName: "",
        linePictureUrl: "",
        statusList: [
          {
            id: 0,
            name: "Unregister",
          },
          {
            id: 1,
            name: "Register",
          },
        ],
      },
      tabIndex: 0,
      isBusy: false,
      // filter: {
      //   lineId: this.$route.params.id,
      //   "userId": 0,
      //   startFilterDate: "",
      //   endFilterDate: "",
      //   page: 1,
      //   pageSize: 10
      // },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      modalMessage: "",
      requestDeleteUser: {
        userId: this.$route.params.id,
      },
      requestUpdateStatus: {
        userId: this.$route.params.id,
      },
    };
  },
  mounted: async function () {
    this.$isLoading = true;
    await this.getCustomerDetail();
    await this.getReport();
    this.$isLoading = false;
  },
  methods: {
    onClickTab(idTab) {
      if (this.tabIndex != idTab) {
        this.tabIndex = idTab;
        this.page = 1;
        this.getReport();
      }
    },
    getCustomerDetail: async function () {
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/customer/line/${this.userId}`,
        null,
        this.$headers,
        null
      );
      if (data.result == 1) {
        this.customer = data.detail.customer;
        //แปลงวันที่
        this.customer.birthDay = this.$moment(this.customer.birthDay).format(
          "YYYY-MM-DD"
        );
        // // // จัดการ Status List
        this.statusActive = this.customer.active;
        // if (data.detail.customer.active == true) this.statusActive = 1;
        // else if (data.detail.customer.active == false) this.statusActive = 0;
        // else this.statusActive = null;
        // // //end จัดการ Status List
      }
    },
    getReport: async function () {
      //this.isBusy = true;
      this.formTable[this.tabIndex].filter.lineId = this.userId;
      this.formTable[this.tabIndex].filter.userId = this.customer.userId;
      let resTableData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/customer/report/line/${
          this.formTable[this.tabIndex].path
        }`,
        null,
        this.$headers,
        this.formTable[this.tabIndex].filter
      );
      if (resTableData.result == 1) {
        this.formTable[this.tabIndex].items = resTableData.detail.data;
        this.formTable[this.tabIndex].rows = resTableData.detail.totalData;
        if (resTableData.detail.totalData > 0) {
          let numRecordFirst =
            this.formTable[this.tabIndex].filter.page *
            this.formTable[this.tabIndex].filter.pageSize;
          numRecordFirst =
            numRecordFirst - this.formTable[this.tabIndex].filter.pageSize + 1;
          let numRecordLast =
            this.formTable[this.tabIndex].filter.page *
              this.formTable[this.tabIndex].filter.pageSize >
            resTableData.detail.totalData
              ? resTableData.detail.totalData
              : this.formTable[this.tabIndex].filter.page *
                this.formTable[this.tabIndex].filter.pageSize;
          this.formTable[this.tabIndex].totalRowMessage =
            "Showing " +
            numRecordFirst +
            " - " +
            numRecordLast +
            " of " +
            resTableData.detail.totalData +
            " entries";
        } else {
          this.formTable[this.tabIndex].totalRowMessage = "";
        }
        //this.isBusy = false;
      }
    },
    pagination(Page) {
      this.formTable[this.tabIndex].filter.page = Page;
      this.getReport();
    },
    hanndleChangePerpage(value) {
      this.formTable[this.tabIndex].filter.page = 1;
      this.formTable[this.tabIndex].filter.pageSize = value;
      this.getReport();
    },
    saveForm: async function (flag) {
      // this.$v.customer.$touch();
      // if (this.$v.customer.$error) return;
      // if (flag == 2) this.customer.active = this.statusActive;
      this.isDisable = true;

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Customer/update`,
        null,
        this.$headers,
        this.customer
      );
      this.modalMessage = resData.message;
      this.isDisable = false;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        if (flag === 1) {
          setTimeout(this.routeToUserList, 2000);
        } else {
          this.$isLoading = true;
          await this.getCustomerDetail();
          this.$isLoading = false;
        }
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    openModalDelete(value) {
      this.modalMessage = "คุณต้องการลบ" + value.customerName + "ใช่หรือไม่?";
      this.$refs.ModalAlertConfirmDelete.show();
    },
    btnDelete: async function () {
      this.$refs.ModalAlertConfirmDelete.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/customer/delete`,
        null,
        this.$headers,
        this.requestDeleteUser
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(this.routeToUserList, 2000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    openModalUpdateStatus(statusFlag) {
      let registMessage = statusFlag ? "Register" : "Unregister";
      this.modalMessage = "คุณต้องการ " + registMessage + " ใช่หรือไม่?";
      // this.statusActive = statusFlag;
      this.$refs.ModalAlertConfirmUpdateStatus.show();
    },
    btnUpdateStatus: async function () {
      this.$refs.ModalAlertConfirmUpdateStatus.hide();
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/customer/unregister`,
        null,
        this.$headers,
        this.requestUpdateStatus
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(this.routeToUserList, 2000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    routeToUserList() {
      this.$router.push("/");
    },
    // onSelectStatusChange(value) {
    //   if (value === 1) this.customer.active = true;
    //   else if (value === 0) this.customer.active = false;
    //   else this.customer.active = null;
    // },
    onChangeCheckBoxContractTenDigit(value) {
      this.customer.contractTenDigit = value;
    },
    exportData: async function (flagFileType, indexTemp) {
      this.$refs.loadingModal.show();

      this.formTable[indexTemp].filter.lineId = this.userId;
      this.formTable[indexTemp].filter.userId = this.customer.userId;

      let fileType = flagFileType === 2 ? ".csv" : ".xlsx";
      this.formTable[indexTemp].filter.fileTypeId = flagFileType;
      axios({
        url: `${this.$baseUrl}/api/customer/export/line/${this.formTable[indexTemp].path}`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: this.formTable[indexTemp].filter,
      }).then((response) => {
        this.$refs.loadingModal.hide();
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        var dateFile = this.$moment().format("DDMMYYYYhhmmss");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `Report ` +
            this.formTable[indexTemp].titleTab.trim() +
            `-` +
            dateFile +
            fileType
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
  },
  validations: {
    customer: {
      customerName: {
        required,
        maxLength: maxLength(50),
      },
      mobileNumber: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      idCard: { required },
      contractNo: { required },
      birthDay: { required },
      // active: { required },
      lineName: { required },
    },
  },
};
</script>

<style scoped>
::v-deep .card-header {
  padding-bottom: 0px !important;
}

.tab-title-collapse {
  border: none;
}

::v-deep .tab-pane {
  padding-top: 0;
}

.btn-collapse {
  border-radius: 0;
  background-color: white !important;
  font-size: 17px;
  padding: 0;
  border: 0;
}

.collapsed > .fa-chevron-down,
:not(.collapsed) > .fa-chevron-right {
  display: none;
}

::v-deep .columnCustomerName {
  display: none !important;
}

.display-custom > label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}

.display-custom > div > p {
  color: #16274a;
  font-size: 16px;
}
.sub-title-name {
  text-align: left;
  color: #16274a;
  font-size: 20px;
  background: #fff !important;
  padding-right: 15px;
  position: relative;
  z-index: 1;
}

.sub-status {
  margin-bottom: 0 !important;
}

.sub-status-link {
  font-size: 16px !important;
}
</style>
